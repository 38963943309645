.header{
  background-color: var(--navy);
  height: 70px;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px 1px 0px rgba(2, 54, 77, 0.2);

  &__renita{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
    transform: translateY(-5px);
  }

  &__logo {
    height: 55px;
    width: auto;
    margin-left: 0.5rem;

    @media only screen and (max-width: 600px) {
      & {
        height: 0;
      } 
    }
  }

  &__image{
    width: 42px;
    height: 26px;
    margin-right: 0.8rem;
  }

  &__dropdown {
    width: auto;
    height: 20px;
    margin-left: 0.3rem;
  }
   
  &__quit-button, &__log-out-button{
    margin-right: 1.6rem;


    &:hover{
      cursor: pointer;
    }
  }

  &__quit-text,  &__log-out-text{
    color: var(--teal-light);
    font-size: 1.1rem;
    font-weight: 600;
  }

  &__tab{
    font-size: 1.3rem;
    color: var(--teal-light);
    margin-bottom: 0.4rem;
    box-sizing: border-box;
    transform: translateY(1px);
    padding: 0 0.1rem 0.4rem 0.1rem;

    @media only screen and (max-width: 700px) {
      & {
        font-size: 1.0rem;
      } 
    }

    &:hover{
      cursor: pointer;
    }
  }
  
  &__tab-active {
    border-bottom: 2px #2C9CA7 solid;
    color: var(--teal-mild);
  }

  &__tabs-container{
    justify-content: space-around;
    width: 40vw;
    max-width: 650px;

    @media only screen and (max-width: 1200px) {
     & {
      width: 50vw;
     } 
    }

    @media only screen and (max-width: 500px) {
      & {
       width: 60vw;
      } 
     }
  }


  &__select-language {
    height: 42px;
    width: 120px;
    background-color: white ;
    border-radius: 5px;
    justify-content: center;
    margin-right: 0.8rem;
    position: relative;

    @media only screen and (max-width: 700px) {
      & {
       display: none;
      } 
     }

    &:hover .header__flag-dropdown,
    &:focus .header__flag-dropdown {
      display: block;
    }
  }

  &__tab-results, &__tab-account {
    padding-bottom: 2px;
  }

  &__tab-account {
    margin-right: 0;

    @media only screen and (max-width: 700px) {
      & {
        margin-right: 5px;
      } 
     }

  }
}


