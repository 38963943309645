.result-table {
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 100%;
    margin-bottom: 40px;

    &__row {
        height: 60px;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 16px;
        border-bottom: 1px solid #D1D1D1;

        &:last-child {
            border-bottom: 1px solid white;
        }

        &--header {
            background-color: #4ca9b5;
            color: white;
        }
    }

    &__download-icon {
        margin-left: 6px;
        margin-right: 2px;
    }

    &__download-text {
        color: var(--teal-dark);
    }

    &__year {
        display: inline-block;
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: var(--px20);
        color: var(--navy-dark);
    }

    &__download-container {

        &:hover {
            cursor: pointer;
        }
    }

    &__row-date {
        margin-right: 10px;
        width: 40%;
    }

    &__text-container {
        width: 80%;
    }
}