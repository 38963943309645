.message-bar {
  visibility: hidden; 
  width: 100%;
  height: 50px;
  background-color: #459c9c;
  color: #fff; 
  text-align: left;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
  left: 0; 
  top: 70px;
  padding-left: 15px;
  padding-right: 10px;
  justify-content: space-between;
  box-sizing: border-box;

  &--error {
    background-color: var(--red);
  }

  &__message {
    font-size: 1.0rem;
  }

  &--visible {
    visibility: visible;
  }

  &__cross-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
