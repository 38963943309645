.log-in{
    background-color: white;
    width: 70vw;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 600px) {
      & {
        width: 95vw;
        margin-top: 6vh;
      } 
    }

    &__info-header {
      font-size: 30px;
      font-weight: 700;
      color: var(--navy-dark);
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &__info-text {
      display: inline-block;
      margin-left: 1rem;
      color: var(--navy);
    }

    &__info-text-conteiner {
      margin-bottom: 1rem;
    }

    &__info-container {
      width: 40vw;
      max-width: 500px;
      padding: 0 5vw;
      box-sizing: border-box;

      @media only screen and (max-width: 850px) {
        & {
         display: none;
        } 
      }

      @media only screen and (min-width: 1000px) {
        & {
          padding: 0 2vw;
        } 
      }
     
    }

    &__info-wrapper {
      justify-content: center;
      margin-top: 16vh;

      @media only screen and (max-height: 600px) {
        & {
          margin-top: 12vh;
        } 
      }

      @media only screen and (max-width: 600px) {
        & {
          margin-top: 6vh;
        } 
      }

      @media only screen and (max-width: 400px) {
        & {
          margin-top: 2vh;
        } 
      }
    }

   &__header{
    margin-top: 1rem;
    font-size: 1.9rem;
    color: var(--onyx);  
   }

   &__content {
    padding-top: 40px;

    & > .input {
      margin-bottom: 1.4rem;
    }
   }

   &__forgot-pass{
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: var(--navy-light);

    &:hover{
      cursor: pointer;
    }
   }

   &__input-container {
     margin-bottom: 1rem;
   }
}