@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--teal-light);
}

:root{
--onyx: #3d3838;
--gray: #ccc8c8;

--navy: #012C3F;
--navy-light: #094F69;
--navy-dark: #012C3F;
--teal-light: #E3F7F8;
--teal-mild: #3BC4CA;
--teal-dark: #2C9CA7;
--gray-dark: #7A7A7A;
--black: #1B1B1B;
--red: #CA3B4C;

//sizes
--px20: 1.25rem;
--px16: 1rem;
--px14: 0.875rem;
--px12: 0.75rem;

}

.flex-row{
  display: flex;

  &-center{
    display: flex;
    align-items: center;
  }
}
.flex-column{
  display: flex;
  flex-direction: column;

  &-center{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}