.reset-pass{
  background-color: white;
  width: 70vw;
  max-width: 500px;
  border-radius: 8px;
  margin: 16vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  padding-top: 2rem;
  padding-bottom: 4rem;

  @media only screen and (max-width: 470px) {
    & {
      width: 95vw;
      margin: 4vh auto;
    } 
  }

  &__header{
    margin-top: 1.5rem;
    margin-bottom: 2.4rem;
    text-align: center;
    font-size: 1.5rem;
    color: var(--navy-dark);
    font-weight: 600;
 
 }

   &__content {
    width: 75%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;

    & > .input {
      margin-bottom: 1.5rem;
    }
    
    @media only screen and (max-width: 470px) {
      & {
        width: 75vw;
      } 
     }
   }

  }