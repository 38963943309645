.input{
  width: 100%;

  &__input {
    height: 42px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    width: 100%;
    // margin-bottom: 1.4rem;

    &--static-height {
      height: 66px;
    }

    &:focus {
      border: 1px solid var(--teal-mild);
      outline: none;
    }

    &--error {
      border: 1px solid var(--red);

      &:focus {
        border: 1px solid var(--red);
        outline: none;
      }
    }
  }

  &__label {
    font-size: var(--px16);
    margin-bottom: 5px;
    color: #1B1B1B;
    font-weight: 400;
  }

  &__info-text {
    display: block;
    margin-top: 10px;
    font-size: var(--px12);
    opacity: 0.6;

    &--no-height {
      height: 0px;
      margin-top: 0px;
    }

    &--error {
      color: var(--red);
    }
  }
  
}