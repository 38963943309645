.account-item {
    width: 100%;   
    border-bottom: 1px solid #D1D1D1;
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;

    &__header-container {
        justify-content: space-between;
    }

    &__left-header {
        font-weight: 600;
        font-size: var(--16px);
        margin-bottom: 0.6rem;
    }

    &__edit-text {
        margin-left: 0.3rem;
    }

    &__edit-container {
        width: 100%;
    }

    &__right-container {

        &--hidden {
            opacity: 0;
        }
    }

    &__margin {
        width: 3rem;
    }

    &__edit-icon {
        width: 15px;
        height: auto;

        &--large {
            width: 20px;
        }
    }

    &__edit-icon-container {
        &:hover {
            cursor: pointer;
        }
    }

    &__input-container {

        @media only screen and (max-width: 500px) {
            & {
                flex-direction: column;
            } 
          }

        &--column {
            flex-direction: column;
        }
    }
}