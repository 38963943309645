.info-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &__modal {
    background-color: white;
    z-index: 3;
    width: 40vw;
    max-width: 500px;
    margin-top: 15vh;
    border-radius: 6px;
    max-height: 80vh;
    overflow-y: auto;

    @media only screen and (max-height: 900px) {
      & {
        margin-top: 5vh;
        max-height: 92vh;
        width: 70vw;
        max-width: 600px;
      } 
    }

    @media only screen and (max-width: 900px) {
      & {
        width: 70vw;
      } 
    }

    @media only screen and (max-width: 400px) {
      & {
        width: 95vw;
      } 
    }
  }

  &__content-container {
    padding: 0 20px;
    font-size: 1.2rem;
    margin-top: -1rem;
  }

  &__button-container {
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }

  &__accept-button {
    margin-top: 0.4rem;
    margin-bottom: 1.0rem;
  }

  &__close-button {
    background-color: #bdbdbd;
  }
}