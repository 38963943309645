.account {

  background-color: white;
  margin: 10vh auto;
  width: 70vw;
  max-width: 700px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  padding-bottom: 3rem;

  @media only screen and (max-width: 700px) {
    & {
      width: 85vw;
      margin-top: 5vh;
    } 
  }

  &__content {
    width: 70%;

    @media only screen and (max-width: 700px) {
      & {
        width: 80%;
      } 
    }
  }

  &__header {
    margin-top: 2rem;
    font-size: 2.1rem;
    color: var(--navy-dark);
    margin-bottom: 2rem;
    font-weight: 600;
 }
 & .input {
    margin: 1rem 0;
  }

  & .select {
    margin: 1rem 0;
  }
  &__button-container {
    margin: 2rem 0 3rem 0;
    justify-content: center;
    width: 80%;
    max-width: 300px;
  }

  &__update-succeed-message {
    font-size: 1.3rem;

    &-container {
      margin: 1rem 0;
    }
  }
}
