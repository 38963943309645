.notification {
    background-color: white;
    margin: 16vh auto;
    width: 70vw;
    max-width: 560px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    padding-top: 2rem;
    padding-bottom: 0.5rem;

    @media only screen and (max-width: 500px) {
        & {
            width: 80vw;
            margin-top: 4vh;
            margin-bottom: 1vh;
        }
    }

    @media only screen and (max-width: 400px) {
        & {
            width: 90vw;
        }
    }

    &__header {
        margin-top: 1rem;
        font-size: 1.5rem;
        color: var(--navy-dark);
        text-align: center;
        font-weight: 500;
    }
    &__content {
        width: 70%;

        @media only screen and (max-width: 400px) {
            & {
                width:  75%;
            }
        }
    }
    &__info-text{
        font-size: 1rem;
        color: var(--onyx); 
        margin-bottom: 2rem;
        font-weight: 500;
      }
}
.return_to_login_page_button {
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
}

