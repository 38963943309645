.results{
  margin: 16vh auto;
  width: 70vw;
  max-width: 600px;
  border-radius: 15px;

  @media only screen and (max-width: 600px) {
    & {
      width: 85vw;
      margin-top: 10vh;
    } 
  }

  &__header {
    font-weight: 700;
    color: var(--navy-dark);
    font-size: 2rem;
    margin-bottom: 2rem;
  }

}

