.forgot-password {
  background-color: white;
  margin: 16vh auto;
  width: 70vw;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);

  @media only screen and (max-width: 470px) {
    & {
      width: 95vw;
      margin: 4vh auto;
    }
  }

  &__header {
    margin-top: 1rem;
    margin-bottom: 2.4rem;
    font-size: 1.5rem;
    color: var(--navy-dark);
    text-align: center;
    font-weight: 500;
  }

  &__info-text {
    font-size: 1rem;
    color: var(--onyx);
    margin-bottom: 2rem;
  }

  &__content {
    width: 75%;
    padding-top: 1rem;
  }

  &__login {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: var(--navy-light);
    font-weight: 500;
    
    &__link {
      text-decoration: underline;
      padding-left: 5px;
      &:hover{
        cursor: pointer;
      }
    }
  }

  &__email-input-container {
    width: 100%;
    margin-bottom: 1.3rem;
  }
}
